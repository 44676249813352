import { createApp } from 'vue'

import App from './App.vue'
import Teams from './components/TeamNames.vue'
import Story from './components/NewsStory.vue'

const app = createApp(App)

app.component('teams-vue',Teams)
app.component('story-vue',Story)

app.mount('#app')