<template>
     <div class="container my-3">
        <div class="row border rounded-2 p-3 bg-body-tertiary">
            <div class="col-12" id="team">
                <h3 class="font-monospace">Have you heard the latest footy rumour?</h3>
                <p class="fs-4 font-monospace" v-if="team.name">
                    <strong>{{ team.name }}</strong> are rumoured to be buying {{ player.name }} as the player was spotted {{ activity.name }}, {{ venue.name }} in {{ team.place }}.
                 </p>
                <p class="h5" v-else>
                    What will the rumour be?
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['team','player','venue','activity']
};
</script>