<template>
    <div class="container my-3">
        <div class="row">
            <div class="col-12">
                <h3>Wow! What's the full story?</h3>
                <p class="fs-5">The news is literaly unbelievable. Click the button below to read everything about <strong>{{ player.name }}'s</strong> unexpected transfer!</p>
                <p><button class="btn btn-success btn-lg" v-on:click="makeStory(team,player,venue,activity)">Read the full story</button></p>
            </div>
        </div>
        <div class="row border rounded-2 p-3 bg-body-tertiary" v-if="data" id="dataDiv">
            <div class="col-12 fs-6 font-monospace" v-html="data">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['team','player','venue','activity'],
    data() {
        return {
            data: null
        };
    },
    methods: {
        async makeStory(team,player,venue,activity) {
            console.log('Making the story');
            this.data = "<p>Writing the story now.</p>"
            const code = process.env.VUE_APP_CHAT;
            const prompt = `You are a tabloid journalist. Write a 150 word story about ${team.name} buying ${player.name} from ${player.team} because they were ${activity.name} in ${venue.name}. Use <p> tags in your response`

                const response = await fetch('https://api.openai.com/v1/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${code}`
                },
                body: JSON.stringify({
                    'model': 'gpt-3.5-turbo-instruct',
                    'max_tokens': 300,
                    'prompt': prompt,
                    'temperature': 1
                })
            }
            );
            const statusCode = response.status;
            if (statusCode == 200) {
                const answer = await response.json();
                this.data = answer.choices[0].text.replace(/^\.\W/,"");
            } else {
                this.data = "</p>Sorry there's no story to read here.</p>";
            }
        }
    },
};
</script>