<template>
  <div class="container my-2">
    <div class="row">
      <div class="col-12">
        <h1 class="text-success text-center display-4">⚽️ Make your own football rumours ⚽️</h1>
        <p class="fs-4">Bored of reading the same old stories about your club buying a player, or a big name 'sending a message' to your manager? Press the big green button below to create your own rumour.</p>
        <p><button class="btn btn-success btn-lg" v-on:click="pickRumour()">Start a rumour</button></p>
      </div>
    </div>
  </div>
  <teams-vue
    v-bind:team="randomTeam" v-bind:player="randomPlayer" v-bind:venue="randomVenue" v-bind:activity="randomActivity"
  />
  <story-vue
    v-bind:team="randomTeam" v-bind:player="randomPlayer" v-bind:venue="randomVenue" v-bind:activity="randomActivity"  
  />
</template>

<script>

import teamJSON from './assets/teams.json';
import playersJSON from './assets/players.json';
import venuesJSON from './assets/venues.json';
import activitiesJSON from './assets/activities.json'

export default {
    data() {
        return {
            allTeams: teamJSON.teams,
            randomTeam: {},
            allPlayers: playersJSON.players,
            randomPlayer: {},
            allVenues: venuesJSON.venues,
            randomVenue: {},
            allActivities: activitiesJSON.activities,
            randomActivity: {}
        }
    },
    methods: {
      pickRumour() {
        const randomTeamNumber = Math.floor(Math.random() * this.allTeams.length);
        this.randomTeam =  this.allTeams[randomTeamNumber];
        const randomPlayerNumber = Math.floor(Math.random() * this.allPlayers.length);
        this.randomPlayer = this.allPlayers[randomPlayerNumber];
        while (this.randomTeam.name == this.randomPlayer.team) {
          const randomPlayerNumber = Math.floor(Math.random() * this.allPlayers.length);
          this.randomPlayer = this.allPlayers[randomPlayerNumber];
        }
        const randomVenueNumber = Math.floor(Math.random() * this.allVenues.length);
        this.randomVenue = this.allVenues[randomVenueNumber];
        const randomActivityNumber = Math.floor(Math.random() * this.allActivities.length);
        this.randomActivity = this.allActivities[randomActivityNumber];
      }
    },
    created() {
      this.pickRumour()
    },
};
</script>

<style></style>